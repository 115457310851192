<template>
  <a-modal
    v-model="isShow"
    :title="isAdd ? '新增' : '修改'"
    @ok="handleOkFunc"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      ref="infoFormModel"
      :model="saveObject"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      :rules="rules"
    >
      <a-form-model-item
        label="应用ID"
        prop="appId"
        :rules="[
          { required: true, message: '请选择应用ID', trigger: 'change' },
        ]"
      >
        <a-input
          v-model="saveObject.appId"
          placeholder="请输入应用ID"
          :disabled="!isAdd"
        />
      </a-form-model-item>

      <a-form-model-item label="应用名称" prop="appName">
        <a-input
          v-model="saveObject.appName"
          placeholder="请输入应用名称"
          :maxLength="20"
        />
      </a-form-model-item>

      <a-form-model-item label="汇付商户号" prop="adapayMchNo">
        <a-input
          v-model="saveObject.adapayMchNo"
          placeholder="请输入汇付商户号"
          :maxLength="50"
        />
      </a-form-model-item>

      <a-form-model-item label="汇付商户名称" prop="adapayMchName">
        <a-input
          v-model="saveObject.adapayMchName"
          placeholder="请输入汇付商户名称"
          :maxLength="50"
        />
      </a-form-model-item>

      <a-form-model-item label="汇付应用ID" prop="adapayAppId">
        <a-input
          v-model="saveObject.adapayAppId"
          placeholder="请输入汇付应用ID"
          :maxLength="50"
        />
      </a-form-model-item>

      <a-form-model-item label="汇付应用名称" prop="adapayAppName">
        <a-input
          v-model="saveObject.adapayAppName"
          placeholder="请输入汇付应用名称"
          :maxLength="50"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { API_URL_ADAPAY_APP_MCH, req } from '@/api/manage'

export default {
  name: 'AdapayAppMchAddOrEdit',
  props: {
    callbackFunc: { type: Function, default: () => () => {} }
  },
  data () {
    return {
      isShow: false,
      isAdd: true,
      confirmLoading: false,
      mchAppList: [], // 商户应用列表
      saveObject: {},
      rules: {
        adapayMchNo: [
          { required: true, message: '请输入汇付商户号', trigger: 'blur' }
        ],
        adapayAppId: [
          { required: true, message: '请输入汇付应用ID', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
  },
  methods: {
    show (isAdd, record) {
      this.isAdd = isAdd
      this.saveObject = {}
      this.confirmLoading = false
      if (this.$refs.infoFormModel) {
        this.$refs.infoFormModel.resetFields()
      }
      if (!this.isAdd) {
        this.saveObject = { ...record }
        this.isShow = true
      } else {
        this.isShow = true
      }
    },

    handleOkFunc () {
      const that = this
      this.$refs.infoFormModel.validate((valid) => {
        if (valid) {
          that.confirmLoading = true
          const promise = that.isAdd
            ? req.add(API_URL_ADAPAY_APP_MCH, that.saveObject)
            : req.updateById(
                API_URL_ADAPAY_APP_MCH,
                that.saveObject.appId,
                that.saveObject
              )
          promise
              .then((res) => {
              that.confirmLoading = false
              that.$message.success(that.isAdd ? '添加成功' : '修改成功')
              that.isShow = false
              that.callbackFunc()
            })
            .catch(() => {
              that.confirmLoading = false
            })
        }
      })
    }
  }
}
</script>
